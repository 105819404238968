<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center class="flex-fill">
            <v-flex xs12 sm8 justify-center align-center d-flex box-shadow>
                <div class="login-form elevation-10">
                    <div class="sijbers-support-logo mb-10">
                        <img src="@/assets/images/logo-sijbers-support.png" />
                    </div>
                    <h1 class="main-title mb-2">Wachtwoord resetten</h1>
                    <p v-if="validatingToken" class="text-center my-7"><v-icon large color="primary">fal fa-spinner-third fa-spin</v-icon></p>
                    <v-alert v-else-if="!isTokenValid" dark color="error" class="text-center my-7">Deze reset link is niet (meer) geldig.</v-alert>
                    <div v-else>
                        <h2 class="subtitle mb-7 px-10">Voer hieronder een nieuw wachtwoord in voor uw account.</h2>
                        <v-form ref="form" @submit.prevent="resetPassword" class="px-5">
                            <v-text-field
                                type="password"
                                outlined
                                hide-details="auto"
                                single-line
                                v-model="firstPassword"
                                label="Nieuw wachtwoord"
                                :rules="firstPasswordRules"
                                required
                                class="mb-2"
                            ></v-text-field>
                            <v-text-field
                                type="password"
                                outlined
                                hide-details="auto"
                                single-line
                                v-model="secondPassword"
                                label="Herhaal wachtwoord"
                                :rules="secondPasswordRules"
                                required
                                class="mb-5"
                            ></v-text-field>
                            <v-btn type="submit" dark color="black" :loading="submitting" :disabled="submitting || firstPassword.length < 6 || secondPassword.length < 6 || firstPassword != secondPassword" block class="mb-2 elevation-0">Wachtwoord resetten</v-btn>
                        </v-form>
                    </div>
                    <div class="px-5">
                        <v-btn v-if="!validatingToken" :to="{name: 'Login'}" block outlined color="black" class="elevation-0">Terug naar inloggen</v-btn>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "ForgotPassword",
    data: () => ({
        firstPassword: '',
        firstPasswordRules: [
            v => !!v || 'Wachtwoord is verplicht',
            v => (!v || v.length >= 6) || 'Minimaal 6 karakters',
        ],
        secondPassword: '',
        submitting: false,
        validatingToken: true,
        isTokenValid: false,
    }),
    computed: {
        secondPasswordRules () {
            return [
                v => !!v || 'Wachtwoord is verplicht',
                v => v === this.firstPassword || 'Wachtwoorden komen niet overeen',
                v => (!v || v.length >= 6) || 'Minimaal 6 karakters',
            ];
        },
    },
    mounted () {
        this.$store.dispatch('validateResetPasswordToken', {
            token: this.$route.params.token,
        }).then(isValid => {
            this.isTokenValid = isValid;
        }).catch((e) => {
            console.error(e);
            this.isTokenValid = false;
        }).finally(() => {
            this.validatingToken = false;
        });
    },
    methods: {
        resetPassword () {
            // Check if form is valid
            if (!this.$refs.form.validate() || this.submitting) {
                return;
            }

            this.submitting = true;

            // Submit data to auth store
            this.$store.dispatch('resetPassword', {
                token: this.$route.params.token,
                password: this.firstPassword,
            }).then(() => {
                this.$store.dispatch('showSnackbar', { color: 'success', text: 'Wachtwoord opnieuw ingesteld'});
                return this.$router.push({ name: 'Login' });
            }).catch((e) => {
                console.error(e);
                return this.$store.dispatch('showSnackbar', { color: 'error', text: 'Serverfout, neem contact op' });
            }).finally(() => {
                this.submitting = false;
            });
        },
    },
}
</script>

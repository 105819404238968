<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center class="flex-fill">
            <v-flex xs12 sm8 justify-center align-center d-flex box-shadow>
                <div class="login-form elevation-10">
                    <div class="sijbers-support-logo mb-10">
                        <img src="@/assets/images/logo-sijbers-support.png" />
                    </div>
                    <h1 class="main-title mb-2">Wachtwoord vergeten?</h1>
                    <p>Voer hieronder uw e-mailadres in en u ontvangt een e-mail om uw wachtwoord opnieuw in te kunnen stellen.</p>
                    <v-form ref="form" @submit.prevent="requestPasswordReset" class="px-5">
                        <v-text-field
                            v-if="!submitted"
                            type="text"
                            outlined
                            hide-details="auto"
                            single-line
                            v-model="username"
                            label="E-mailadres"
                            :loading="submitting"
                            :rules="usernameRules"
                            required
                            class="mb-4"
                        ></v-text-field>
                        <v-alert color="success" dark v-if="submitted" class="mb-5">
                            Indien er al een account voor dit e-mailadres bestaat is er zojuist een e-mail verzonden met verdere instructies om uw wachtwoord te resetten.
                        </v-alert>
                        <v-btn type="submit" dark color="black" v-if="!submitted" :loading="submitting" :disabled="submitting || username.length < 1" block class="mb-2 elevation-0">Wachtwoord resetten</v-btn>
                        <v-btn :to="{name: 'Login'}" block outlined color="black" class="elevation-0">Terug naar inloggen</v-btn>
                    </v-form>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "ForgotPassword",
    data: () => ({
        username: '',
        usernameRules: [
            v => !!v || 'E-mailadres is verplicht'
        ],
        submitted: false,
        submitting: false,
    }),
    methods: {
        back () {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push({name: 'Login'});
            }
        },
        requestPasswordReset () {
            // Check if form is valid
            if (!this.$refs.form.validate() || this.submitted) {
                return;
            }

            this.submitting = true;

            // Submit data to auth store
            this.$store.dispatch('forgotPassword', {
                username: this.username,
            }).then(() => {
                this.submitted = true;
                return this.$store.dispatch('showSnackbar', { color: 'success', text: 'Wachtwoord reset link verzonden'});
            }).catch((e) => {
                console.error(e);
                return this.$store.dispatch('showSnackbar', { color: 'error', text: 'Serverfout, neem contact op' });
            }).finally(() => {
                this.submitting = false;
            });
        },
    },
}
</script>
